/*

COLORS: 
primary text:           #4a4a4a;
accent/border grey:     #959595;
pale yellow bkg:        #f6f7db;
red:                    #ED2525; rgb(237,37,37);
orange:                 #F37721; rgb(243,119,33);
green:                  #008062; rgb(0,128,98);

FONTS: 
Gotham Black
    font-family: 'Gotham A', 'Gotham B'; 
    font-weight: 800; 
    font-style: normal;
Gotham Black Italic
    font-family: 'Gotham A', 'Gotham B'; 
    font-weight: 800; 
    font-style: italic;
  
*/

html {
    height: 100%;
    min-height: 101%;
    margin-bottom: 1px;
    background-color: #fff;
}

body {
    height: 100%;
    color: #4a4a4a;
    font-family: Verdana, Helvetica, Arial, sans-serif;
}

@media (min-width: 999px) {
    body {
        background-image: url('../images/bkg_page_top.png');

        background-repeat: repeat-x;
        background-position: 0 -40px;
    }
}

@media (min-width: 992px) {
    body {
        background-position: 0 0;
    }
}

h1,
.h1 {
    color: #008062;
    font-size: 45px;
    /* font-family: 'Gotham A', 'Gotham B', Verdana, Helvetica, Arial, sans-serif; */
    font-family: 'Montserrat', Verdana, Helvetica, Arial, sans-serif;
    font-weight: 800;
    font-style: normal;
}


@media (max-width: 767px) {

    h1,
    .h1 {
        font-size: 30px;
    }
}

h2,
.h2 {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

h3,
.h3 {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}

h4,
.h4 {
    font-size: 14px;
    font-weight: bold;
}

a {
    color: #ED2525;
    text-decoration: none;
}

a:hover,
a:focus {
    color: #ED2525;
    text-decoration: underline;
}

a:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}



/* ==| COLOR STYLES |================================================== */
.text-green,
.text-green:hover,
.text-green a,
.text-green a:hover {
    color: #008062;
}

.text-orange,
.text-orange:hover,
.text-orange a,
.text-orange a:hover {
    color: #F37721;
}

.text-red,
.text-red:hover,
.text-red a,
.text-red a:hover {
    color: #ED2525;
}

.block-green {
    color: #fff;
    background-color: #008062;
}

.block-orange {
    color: #fff;
    background-color: #F37721;
}

.block-red {
    color: #fff;
    background-color: #ED2525;
}

.block-green>*,
.block-orange>*,
.block-red>* {
    color: #fff;
}

.border-green {
    border-color: #008062;
}

.border-orange {
    border-color: #F37721;
}

.border-red {
    border-color: #ED2525;
}

.btn-green,
.btn-orange,
.btn-red {
    color: #fff;
    font-size: 16px;
    font-family: 'Montserrat', Verdana, Helvetica, Arial, sans-serif;
    font-weight: 800;
    font-style: normal;
    text-transform: uppercase;
    text-decoration: none;
    border: none;
    border-radius: 0;
}

.btn-green:hover,
.btn-green:focus,
.btn-orange:hover,
.btn-orange:focus,
.btn-red:hover,
.btn-red:focus {
    color: #fff;
    text-decoration: none;
}

.btn-green:active,
.btn-green.active,
.btn-orange:active,
.btn-orange.active,
.btn-red:active,
.btn-red.active {}

.btn-green {
    background-color: #008062;
}

.btn-orange {
    background-color: #F37721;
}

.btn-red {
    background-color: #ED2525;
}

.footer-active:hover,
.footer-active:focus,
.footer-active {
    color: #f37721 !important;
}

@media (max-width: 767px) {
    .btn-xs-only {
        display: inline-block;
        padding: 6px 12px;
        /* padding: 0 10px; */
        margin-bottom: 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
    }

    .btn-xs-only:focus,
    .btn-xs-only:active:focus,
    .btn-xs-only.active:focus {
        outline: thin dotted;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }

    .btn-xs-only:hover,
    .btn-xs-only:focus {
        color: #333;
        text-decoration: none;
    }

    .btn-xs-only:active,
    .btn-xs-only.active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    }

    .btn-green-xs,
    .btn-orange-xs,
    .btn-red-xs {
        color: #fff;
        font-size: 14px;
        font-family: 'Montserrat', Verdana, Helvetica, Arial, sans-serif;
        font-weight: 800;
        font-style: normal;
        text-transform: uppercase;
        text-decoration: none;
        border: none;
        border-radius: 0;
    }

    .btn-green-xs:hover,
    .btn-green-xs:focus,
    .btn-orange-xs:hover,
    .btn-orange-xs:focus,
    .btn-red-xs:hover,
    .btn-red-xs:focus {
        color: #fff;
        text-decoration: none;
    }

    .btn-green-xs:active,
    .btn-green-xs.active,
    .btn-orange-xs:active,
    .btn-orange-xs.active,
    .btn-red-xs:active,
    .btn-red-xs.active {}

    .btn-green-xs {
        background-color: #008062;
    }

    .btn-orange-xs {
        background-color: #F37721;
    }

    .btn-red-xs {
        background-color: #ED2525;
    }
}



/* ==| GLOBAL BITS |================================================== */
/* ==| promo blocks |== */
.promo {
    width: 100%;
    max-width: 330px;
    margin: 0 auto;
    padding: 15px;
    font-size: 17px;
    text-align: center;
    background-color: #f6f7db;
}

.promo-inner {
    overflow: hidden;
    width: 100%;
    padding: 2px;
    border: solid 2px #F37721;
}

.promo .promo-content {
    padding: 0 13px 13px 13px;
}

.promo h2 {
    color: #ED2525;
    font-size: 20px;
    font-family: 'Montserrat', Verdana, Helvetica, Arial, sans-serif;
    font-weight: 800;
    font-style: normal;
}

@media (min-width: 600px) {
    .promo h2 {
        font-size: 30px;
    }
}

@media (min-width: 999px) {}

@media (min-width: 992px) {
    .promo h2 {
        font-size: 20px;
    }
}

@media (min-width: 1200px) {
    .promo h2 {
        font-size: 30px;
    }
}

/* ==| arrows |== */
.arrow {
    display: block;
    width: 0;
    height: 0;
    margin-right: 4px;
    margin-left: 4px;
    vertical-align: middle;
    border-style: solid;
    border-width: 10px;
    border-color: transparent;
}

.arrow.down {
    border-width: 10px 7px 0 7px;
    border-color: #4a4a4a transparent transparent transparent;
}

.arrow.left {
    border-width: 7px 10px 7px 0;
    border-color: transparent #4a4a4a transparent transparent;
}

.arrow.up {
    border-width: 0 7px 10px 7px;
    border-color: transparent transparent #4a4a4a transparent;
}

.arrow.right {
    border-width: 7px 0 7px 10px;
    border-color: transparent transparent transparent #4a4a4a;
}

.arrow.down.thin {
    border-width: 14px 6px 0 6px;
}

.arrow.left.thin {
    border-width: 6px 14px 6px 0;
}

.arrow.up.thin {
    border-width: 0 6px 14px 6px;
}

.arrow.right.thin {
    border-width: 6px 0 6px 14px;
}

.arrow.down.orange {
    border-color: #F37721 transparent transparent transparent;
}

.arrow.left.orange {
    border-color: transparent #F37721 transparent transparent;
}

.arrow.up.orange {
    border-color: transparent transparent #F37721 transparent;
}

.arrow.right.orange {
    border-color: transparent transparent transparent #F37721;
}

.arrow.down.red {
    border-color: #ED2525 transparent transparent transparent;
}

.arrow.left.red {
    border-color: transparent #ED2525 transparent transparent;
}

.arrow.up.red {
    border-color: transparent transparent #ED2525 transparent;
}

.arrow.right.red {
    border-color: transparent transparent transparent #ED2525;
}

.arrow.down.green {
    border-color: #008062 transparent transparent transparent;
}

.arrow.left.green {
    border-color: transparent #008062 transparent transparent;
}

.arrow.up.green {
    border-color: transparent transparent #008062 transparent;
}

.arrow.right.green {
    border-color: transparent transparent transparent #008062;
}



/* ==| FORMS |================================================== */
.form-control {
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #4a4a4a;
    background-color: #f6f7db;
    /* background-image: none; */
    border: none;
    border-radius: 10px;
    -webkit-box-shadow: inset 1px 1px 2px 0 rgba(140, 140, 140, .55);
    box-shadow: inset 1px 1px 2px 0 rgba(140, 140, 140, .55);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control.custom-select {
    overflow: hidden;
    /* background-image: url('../images/select_arrows.png'); */
    background-repeat: no-repeat;
    background-position: right 0;
}

.form-control.custom-select .custom-selectInner {
    display: block;
    height: 22px;
    overflow: hidden;
}

.form-group.select-group {
    position: relative;
}

/*.custom-select:after {
    content: "";
    position: absolute;
    right: 12px;
    top: 10px;
    width: 0;
    height: 0;
    margin-top: 0;
    margin-left: 0;
    border-style: solid;
    border-width: 14px 11px 0 11px;
    border-color: #b4b4b4 transparent transparent transparent;
}*/
.custom-select.custom-selectOpen {
    background-position: right -34px;
    border-radius: 10px 10px 0 0;
}

.custom-select.custom-selectFocus,
.form-control:focus {
    border: 1px solid #00AB83;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(0, 128, 98, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(0, 128, 98, .6);
}

/*.custom-select.custom-selectOpen:after {
    content: "";
    position: absolute;
    right: 12px;
    top: 10px;
    width: 0;
    height: 0;
    margin-top: 0;
    margin-left: 0;
    border-style: solid;
    border-width: 0 11px 14px 11px;
    border-color: transparent transparent #b4b4b4 transparent;
}*/
.form-control::-moz-placeholder {
    color: #4a4a4a;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #4a4a4a;
}

.form-control::-webkit-input-placeholder {
    color: #4a4a4a;
}

.form-group.required .form-control:after,
.form-group.required .help-block.radio-help:after,
.form-group.required .help-block.checkbox-help:after {
    color: #a94442;
    content: "*";
    font-family: "Glyphicons Halflings";
    font-size: 8px;
    font-weight: normal;
    position: absolute;
    top: 4px;
}

.form-group.required .help-block.radio-help,
.form-group.required .help-block.checkbox-help {
    color: #333333;
    margin-bottom: 0;
}


/* ==| #PAGE |================================================== */
#page {
    text-align: center;
}

@media (min-width: 999px) {
    #page {
        /* background-image: url('../images/bkg_page_bottom_140.png'); */
        background-repeat: repeat-x;
        background-position: left bottom;
    }
}

@media (min-width: 992px) {
    #page {
        background-image: url('../images/bkg_page_bottom.png');
    }
}

#page_wrapper {
    text-align: left;
}

@media (min-width: 999px) {
    #page_wrapper {
        position: relative;
        min-height: 100%;
        /* box-shadow: 0 0 10px 0 rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.5); */
    }

    #page_wrapper:before {
        position: absolute;
        left: -10px;
        top: 0;
        content: " ";
        display: block;
        width: 10px;
        height: 100%;
        box-shadow: inset -10px 0 10px -10px rgba(0, 0, 0, .5);
        -webkit-box-shadow: inset -10px 0 10px -10px rgba(0, 0, 0, .5);
    }

    #page_wrapper:after {
        position: absolute;
        right: -10px;
        top: 0;
        content: " ";
        display: block;
        width: 10px;
        height: 100%;
        box-shadow: inset 10px 0 10px -10px rgba(0, 0, 0, .5);
        -webkit-box-shadow: inset 10px 0 10px -10px rgba(0, 0, 0, .5);
    }
}



/* ==| #HEADER |================================================== */
#site_header {
    background-color: #F6F7DB !important;
    border: none;
    border-radius: 0;
}

#site_header .navbar {
    margin-bottom: 0;
    border-radius: 0;
}

#site_header .navbar-default {
    background-color: transparent;
    border: none;
}

#site_header .navbar-brand {
    float: none;
    display: block;
    width: 120px;
    height: 80px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    text-indent: -9999px;
    /* background-image: url("../images/logo_sei.png"); */
    background-image: url('../images/SEIFuels_logo_lg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    outline: none;
}

path {
    cursor: pointer;
}

@media (min-width: 999px) {
    #site_header {
        height: 160px;
        background-color: transparent;
    }

    #site_header .navbar-brand {
        display: block;
        width: 150px;
        height: 100px;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 0;
        margin-right: 0;
        padding: 0;
    }

    #site_header .nav.nav-primary {
        clear: right;
    }
}

@media (min-width: 992px) {
    #site_header {
        height: 200px;
        background-color: transparent;
    }

    #site_header .navbar-brand {
        width: 260px;
        height: 130px;
        margin-top: 30px;
        margin-bottom: 35px;
    }
}

#site_header .navbar-collapse {
    margin-left: -15px;
    margin-right: -15px;
    background-color: #fff;
}

@media (min-width: 999px) {
    #site_header .navbar-collapse {
        margin-left: 0;
        margin-right: 0;
        background-color: transparent;
    }
}

@media (max-width: 767px) {
    .navbar-default .navbar-nav>li {
        padding-top: 3px;
    }

    .navbar-default .navbar-nav>li:first-of-type {
        padding-top: 0;
    }
}

.navbar-right.access {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #fff;
    background-color: #565656;
}

.navbar-right.access a {
    color: #fff;
}

.navbar-right.access .site-links {
    margin-bottom: 0;
}

.navbar-right.access .forgot-password {
    padding-top: 8px;
    font-size: 11px;
}

.navbar-right.access .forgot-password a {
    text-decoration: underline;
}

.navbar-right.access form {}

.navbar-right.access label {
    margin-bottom: 5px;
    font-weight: normal;
}

.navbar-right.access .form-control {
    width: 110px;
    height: 20px;
    padding: 2px 5px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #4a4a4a;
    background-color: #fff;
    background-image: none;
    border: none;
    border-radius: 5px;
    -webkit-box-shadow: inset 1px 1px 2px 0 rgba(140, 140, 140, .55);
    box-shadow: inset 1px 1px 2px 0 rgba(140, 140, 140, .55);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.navbar-right.access .form-control:focus {
    border: 1px solid #00AB83;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(0, 128, 98, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(0, 128, 98, .6);
}

.navbar-right.access .btn {
    margin-top: -2px;
    padding: 0 10px;
    font-size: 14px;
    line-height: 22px;
}

.navbar-right.access #login_form {
    clear: right;
    overflow: hidden;
    padding-top: 10px;
}

@media (max-width: 767px) {
    .navbar-right.access .site-links .btn-xs-only {
        margin-bottom: 10px;
    }

    .navbar-right.access .site-links .btn-xs-only:last-of-type {
        margin-bottom: 0;
    }

    .navbar-right.access .btn-xs-only {
        margin-bottom: 10px;
    }
}

@media (min-width: 999px) {
    .navbar-right.access {
        min-height: 95px;
        margin-right: 0;
        margin-left: 0;
        padding-right: 0;
        padding-left: 0;
        padding-top: 5px;
        padding-bottom: 0;
        color: #4a4a4a;
        background-color: transparent;
    }

    .navbar-right.access a {
        color: #4a4a4a;
    }

    .navbar-right.access .site-links {
        clear: right;
        float: right;
    }

    .navbar-right.access #login_form {}

    .navbar-right.access .forgot-password {
        clear: right;
        float: right;
    }

    .navbar-right.access form {
        clear: right;
        float: right;
    }
}

@media (min-width: 992px) {
    .navbar-right.access {
        min-height: 115px;
        padding-top: 25px;
        background-color: transparent;
    }

    .navbar-right.access form {}
}

@media (min-width: 1200px) {
    .navbar-right.access {}

    .navbar-right.access form {}
}

.navbar-default .navbar-nav>li>a {
    color: #fff;
    font-size: 18px;
    font-family: 'Montserrat', Verdana, Helvetica, Arial, sans-serif;
    font-weight: 800;
    font-style: normal;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #565656;
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a:focus {
    color: #fff;
    background-color: #565656;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    color: #fff;
    background-color: #565656;
}

.navbar-default .navbar-toggle {
    border: none;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: #F37721;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #363636;
}

.navbar-default .navbar-toggle:hover .icon-bar,
.navbar-default .navbar-toggle:focus .icon-bar {
    background-color: #f6f7db;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border: none;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:hover,
.navbar-default .navbar-nav>.open>a:focus {
    color: #fff;
    background-color: #565656;
}

.navbar-nav .dropdown-menu {
    position: absolute;
}

.dropdown-toggle {
    cursor: pointer;
}

@media (max-width: 767px) {
    .navbar-nav {
        margin: 3px -15px 7.5px -15px;
    }
}

@media (min-width: 999px) {
    .navbar-default .navbar-nav>.dropdown>.dropdown-toggle>.caret {
        display: none;
    }

    .navbar-default .nav-primary {
        margin-top: 3px;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .navbar-default .navbar-nav>li {
        border-left: solid 1px #ED2525;
    }

    .navbar-default .navbar-nav>li:first-of-type {
        border-left: none;
    }

    .navbar-default .navbar-nav>li>a {
        padding-top: 0;
        padding-bottom: 0;
        color: #262425;
        font-size: 16px;
        background-color: transparent;
    }

    .navbar-default .navbar-nav>li>a:hover,
    .navbar-default .navbar-nav>li>a:focus {
        color: #F37721;
        background-color: transparent;
    }

    .navbar-default .navbar-nav>.active>a,
    .navbar-default .navbar-nav>.active>a:hover,
    .navbar-default .navbar-nav>.active>a:focus {
        color: #F37721;
        background-color: transparent;
    }

    .navbar-default .navbar-nav>.open>a,
    .navbar-default .navbar-nav>.open>a:hover,
    .navbar-default .navbar-nav>.open>a:focus {
        color: #F37721;
        background-color: transparent;
    }
}

@media (min-width: 992px) {
    .navbar-default .nav-primary {
        margin-top: 15px;
    }

    .navbar-default .navbar-nav>li>a {
        font-size: 17px;
    }
}

@media (min-width: 1200px) {
    .navbar-default .navbar-nav>li>a {
        font-size: 18px;
    }
}

.navbar-default .navbar-nav .open .navbar-nav>li>.dropdown-menu {
    margin-top: 0;
    border: none;
    border-radius: 0;
}

.navbar-default .navbar-nav .open .dropdown-menu>li {
    padding: 0 15px;
}

.navbar-default .navbar-nav .open .dropdown-menu>li>a {
    padding: 5px 0;
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
    border-top: solid 2px #ED2525;
}

.navbar-default .navbar-nav .open .dropdown-menu>li:first-of-type>a {
    border-top: none;
}

.navbar-default .navbar-nav .open .dropdown-menu>li>a:hover,
.navbar-default .navbar-nav .open .dropdown-menu>li>a:focus {
    color: #ED2525;
    text-decoration: none;
    background-color: transparent;
}

.navbar-default .navbar-nav .open .dropdown-menu>.active>a,
.navbar-default .navbar-nav .open .dropdown-menu>.active>a:hover,
.navbar-default .navbar-nav .open .dropdown-menu>.active>a:focus {
    color: #ED2525;
    text-decoration: none;
    background-color: transparent;
    outline: 0;
}

@media (min-width: 999px) {
    .navbar-right .dropdown-menu {
        width: 200px;
        left: 50%;
        right: 0;
    }

    .navbar-default .navbar-nav .open>.dropdown-menu {
        margin-top: 20px;
        border-top: solid 7px #ED2525;
        border-right: none;
        border-bottom: none;
        border-left: none;
        border-radius: 0;
    }

    .navbar-default .navbar-nav .open>.dropdown-menu:before {
        content: " ";
        position: absolute;
        left: 50%;
        top: -19px;
        width: 0;
        height: 0;
        margin-top: -12px;
        margin-left: -12px;
        border: solid;
        border-color: transparent transparent #ED2525 transparent;
        border-width: 12px;
    }

    .navbar-default .navbar-nav .open .dropdown-menu>li>a {
        padding: 7px 3px;
        text-align: center;
        white-space: normal;
        border-top: solid 5px #008062;
    }
}



/* ==| #MAIN |================================================== */
#main {
    text-align: left;
}

@media (min-width: 999px) {
    #main {
        min-height: 600px;
        /* padding-left: 60px;
        padding-top: 20px; */
    }
}

#main img {
    max-width: 100%;
    height: auto;
}

/* ==| breadcrumbs |== */
.breadcrumb-nav {
    min-height: 36px !important;
}

.breadcrumb {
    margin-bottom: 0;
    padding: 12px 0 0 0;
    font-size: 17px;
    background-color: transparent;
    border-radius: 0;
}

.breadcrumb>li+li:before {
    padding: 0 5px;
    color: #ccc;
    content: "\003e";
}

.breadcrumb>.active {
    color: #777;
}

#content {
    padding-top: 0;
    padding-bottom: 30px;
}

@media (min-width: 999px) {
    #content {
        padding-bottom: 40px;
    }
}

#sidebar {
    padding-top: 0;
    padding-bottom: 30px;
}

@media (min-width: 999px) {
    #sidebar {
        padding-bottom: 40px;
    }
}

#sidebar .testimonial-block {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    background-image: url('../images/quote_open.png');
    background-repeat: no-repeat;
    background-position: left top;
}

#sidebar .testimonial-block .quote {
    padding-bottom: 20px;
    color: #ED2525;
    font-size: 16px;
    font-family: 'Montserrat', Verdana, Helvetica, Arial, sans-serif;
    font-weight: 800;
    font-style: normal;
    line-height: 1.6;
    background-image: url('../images/quote_close.png');
    background-repeat: no-repeat;
    background-position: right bottom;
}

#sidebar .testimonial-block .divider {
    display: block;
    width: 25%;
    height: 0;
    margin-top: -8px;
    margin-right: auto;
    margin-left: auto;
    vertical-align: middle;
    border-bottom: solid 3px #F37721;
}

#sidebar .testimonial-block .attribute {
    padding-top: 15px;
    font-size: 15px;
}


/* ==| #FOOTER |================================================== */
#site_footer {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    padding-top: 20px;
    padding-bottom: 15px;
    background-color: #F6F7DB !important;
}

#footer_wrapper {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@media (min-width: 999px) {
    #site_footer {
        height: 140px;
        padding-top: 20px;
        padding-bottom: 0;
        background-color: transparent;
    }

    /*#footer_wrapper {
        height: 140px;
        padding-top: 20px;
    }
    #footer_wrapper:before {
        position: absolute;
        left: -10px;
        top: 0;
        content: " ";
        display: block;
        width: 10px;
        height: 100%;
        box-shadow: inset -10px 0 10px -10px rgba(0,0,0,.5);
        -webkit-box-shadow: inset -10px 0 10px -10px rgba(0,0,0,.5);
    }
    #footer_wrapper:after {
        position: absolute;
        right: -10px;
        top: 0;
        content: " ";
        display: block;
        width: 10px;
        height: 100%;
        box-shadow: inset 10px 0 10px -10px rgba(0,0,0,.5);
        -webkit-box-shadow: inset 10px 0 10px -10px rgba(0,0,0,.5);
    }*/
}

@media (min-width: 992px) {
    #site_footer {
        height: 120px;
    }

    /*#footer_wrapper {
        height: 120px;
    }*/
}

#site_footer .nav-footer {
    margin: 0 0 15px 0;
    padding: 0;
    list-style-type: none;
    text-align: center;
}

#site_footer .nav-footer li {
    display: inline-block;
    padding: 0 10px;
}

#site_footer .nav-footer a {
    color: #211f1c;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
}

#site_footer .legal {
    clear: both;
    font-size: 11px;
    text-align: center;
}

#site_footer .legal a {
    color: #4a4a4a;
}

@media (min-width: 992px) {
    #site_footer .nav-footer {
        position: relative;
        left: 50%;
        float: left;
        margin: 0 0 12px 0;
        padding: 0;
        list-style-type: none;
    }

    #site_footer .nav-footer li {
        position: relative;
        right: 50%;
        float: left;
        display: block;
        padding: 0 10px;
        line-height: 1;
        border-left: solid 1px #211f1c;
    }

    #site_footer .nav-footer li:first-of-type {
        border-left: none;
    }
}



/* ==| HOME |================================================== */
.carousel-indicators {
    bottom: 0;
    height: 20px;
}

.carousel-indicators li {
    width: 17px;
    height: 17px;
    margin: 0 4px;
    border: 3px solid #fff;
    border-radius: 10px;
}

.carousel-indicators .active {
    width: 17px;
    height: 17px;
    margin: 0 4px;
}

@media (min-width: 999px) {
    .carousel-indicators {
        bottom: 0;
        top: 20px;
        left: 40px;
        width: 60%;
        margin-left: 0;
        text-align: left;
    }
}



/* ==| FAQ |================================================== */
.faq-listing {
    padding-bottom: 30px;
}

.faq-listing h2 {
    padding-bottom: 10px;
}

.faq-listing .item {
    padding-bottom: 20px;
    padding-top: 10px;
    border-bottom: solid 1px #959595;
}

.faq-listing .item:first-of-type {
    padding-top: 0;
}

.faq-listing h3 a {
    color: #4a4a4a;
}

.faq-listing h3 .arrow.right {
    float: left;
    margin: 3px 16px 0 0;
}

.faq-listing h3 .arrow.down {
    float: left;
    margin: 2px 18px 0 0;
}

.faq-listing .answer {
    padding-left: 30px;
}



/* ==| BRANDS |================================================== */
.brands-logos,
.brands-listing {
    padding-bottom: 30px;
}

.brands-listing .item .image {
    padding-top: 25px;
    text-align: center;
}

.brands-logos .image {
    padding-top: 25px;
}

.brands-logos .image:nth-child(3n-2) {
    clear: left;
}

.brands-logos .image div {
    position: relative;
    text-align: center;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
}

.brands-logos .image a,
.brands-logos .image span {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 0;
}

.brands-logos .image a:before,
.brands-logos .image span:before {
    content: ' ';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}

.brands-logos .image img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
    vertical-align: middle;
}

/* home page */
/*.home .brands-logos .image {
    float: left;
    width: 20%;
    padding: 10px 25px;
}*/
.home .brands-logos .image:nth-child(3n-2) {
    clear: none;
}




/* ==| TESTIMONIALS |================================================== */
.testimonials-listing {
    padding-bottom: 30px;
}

.testimonials-listing .item .image {
    padding-top: 25px;
    text-align: center;
}

#main .testimonials-listing .item .image img {
    display: block;
    margin: 0 auto;
    max-width: 100px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .5);
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .5);
}

@media (min-width: 992px) {
    #main .testimonials-listing .item .image img {
        max-width: 100px;
    }
}

.testimonials-listing .item h2 small {
    font-size: 80%;
}



/* ==| CONSULTANTS |================================================== */
.consultants-listing .item:nth-child(2n+1) {
    clear: left;
}

.consultants-listing .item h3 {
    margin-bottom: 0;
}



/* ==| PHOTO GALLERIES |================================================== */
.gallery-listing {
    padding-top: 10px;
    padding-bottom: 5px;
}

#gallery_slideshow {
    margin-bottom: 10px;
}

#slider_thumbs {
    margin-bottom: 10px;
}

#slider_thumbs .item a {
    width: 22%;
    float: left;
    margin: 0 1.5%;
}

#slider_thumbs .item a.selected img {
    border: solid 2px #ED2525;
}

#slider_thumbs .item {
    overflow: hidden;
    padding-left: 2%;
    padding-right: 2%;
}

/*.carousel-control {
  opacity: 0.3;
  filter: alpha(opacity=30);
}*/
/*#slider_thumbs .carousel-control {
    width: 5%;
    max-width: 40px;
}*/
.carousel-control.left {
    background-image: none;
    filter: progidnone;
}

.carousel-control.right {
    background-image: none;
    filter: progidnone;
}

.carousel-control.left:hover {
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
}

.carousel-control.right:hover {
    left: auto;
    right: 0;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
}

/*.carousel-control .fa-chevron-left,
.carousel-control .fa-chevron-right {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    margin-top: -20px;
}
.carousel-control .fa-chevron-left {
    left: 50%;
    margin-left: -15px;
}
.carousel-control .fa-chevron-right {
    right: 50%;
    margin-right: -15px;
}
@media (min-width: 999px) {
    .carousel-control .fa-chevron-left {
        left: 50%;
        margin-left: -15px;
    }
    .carousel-control .fa-chevron-right {
        right: 50%;
        margin-right: -15px;
    }
}
@media (min-width: 1022px) {
    .carousel-control .fa-chevron-left {
        left: 50%;
        margin-left: -30px;
    }
    .carousel-control .fa-chevron-right {
        right: 50%;
        margin-right: -30px;
    }
}*/

path.rsm-geography {
    outline: none;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        padding: 7px 16px;
    }
}
.active {
    color: red;
}

.header-nav {
    text-decoration: none !important;
}

.dropdown-menu {
    --bs-dropdown-link-hover-color: none;
    --bs-dropdown-link-hover-bg: none;
    --bs-dropdown-link-active-color: none;
    --bs-dropdown-link-active-bg: none; 
    --bs-dropdown-color: #ed2525;
}

.dropdown-toggle {
    text-decoration: none !important;
}