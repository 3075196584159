@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./Assests/C_public";
@import "./Assests/c_bootstrap";
//@import "./Assests/bootstrap";
//@import "../node_modules/bootstrap/dist/css/bootstrap";

.bold-text {
    font-weight: bold;
    font-size: 18px !important;
}

// .body-allignment {
//     padding: 20px;
// }

// .container-box {
//     box-shadow: 1px 1px 10px 0px #343a40;
// }

// .body {
//     font-family: Verdana, Geneva, Tahoma, sans-serif !important;
// }

.navbar {
    --bs-navbar-color: black !important;
}

.navbar-nav {
    --bs-nav-link-hover-color: #f37721 !important;

}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: #f37721 !important;

}

.navbar-toggler {
    border: var(--bs-border-width) solid rgba(255, 255, 255, 0.15);
    color: #f37721;

}


// .dropdown-item {
//     padding: 7px 3px !important;
//     text-align: center !important;
//     white-space: normal;
//     border-top: solid 5px #008062;

// }

path {
    pointer-events: all;
}

path:hover {
    //opacity: 0.50;
    cursor: pointer;
    fill: #CC0000;
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) {
    #site_header .nav.nav-primary {
        font-size: 18px;
        font-weight: bolder;
        color: #000;
    }
}

@media (min-width: 768px) {
    .navbar-right .dropdown-menu {
        width: 250px;
        right: 0;
        margin-top: 2px;
        border-top: solid 9px #ED2525;
        border-radius: 0;
        left: -44px;
    }
}

.dropdown-item {
    color: #000;
    white-space: pre-wrap;
    font-weight: bold;
    background-color: white;
    text-align: center;
}

@media (min-width: 992px) {
    .navbar-right.access {
        font-size: 14px;
    }
}


@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }

    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.btn-green:hover,
.btn-green:focus,
.btn-orange:hover,
.btn-orange:focus,
.btn-red:hover,
.btn-red:focus {
    background-color: #703104;
    text-decoration: none;
}

.navbar {
    --bs-navbar-color: black;
}

.form-control {
    height: initial;
}


//   .navbar {
//     position: relative;
//     min-height: 50px;
//     margin-bottom: 20px;
//     border: 1px solid transparent;
//   }
//   @media (min-width: 768px) {
//     .navbar {
//       border-radius: 4px;
//     }
//   }
//   Nav > Nav.Link {
//     line-height: 20px;
//   }